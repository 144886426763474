import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import { Box, Container, Typography } from "@mui/material";
import { Divider } from "@mui/material";

import DoneOutlineIcon from "@mui/icons-material/DoneOutline";

import theme from "../../theme";
import CompanyName from "../../utils/CompanyName";

export default function ClientsWhy(props) {
  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="xl" sx={{ marginTop: theme.spacing(4) }}>
        <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
          <Box>
            <Divider textAlign="left">
              <Typography align="left" variant="h3">
                What we do best
              </Typography>
            </Divider>
          </Box>
          <Box
            sx={{
              padding: "0% 5% 0% 5%",
              display: "flex",
              flexDirection: "column",
              gap: "1%",
            }}
          >
            <Box
              sx={{
                padding: "0% 5% 0% 5%",
                display: "flex",
                flexDirection: "row",
                gap: "1%",
                marginTop: "2%",
              }}
            >
              <Typography variant="h5" align="left" margin="auto">
                <strong>Tailored Solutions for Every Need:</strong> One compelling reason to choose <CompanyName /> is
                our commitment to providing tailored solutions for every client. We understand that each business is
                unique, facing its own set of challenges and opportunities. Our team takes the time to comprehensively
                assess your requirements, ensuring that our business support services, whether in logistics, HR, health
                and safety, project management, property and building maintenance, or asset management, are precisely
                aligned with your goals. This personalized approach guarantees that our solutions seamlessly integrate
                with your business model, maximizing efficiency and contributing to your overall success.
              </Typography>
              <DoneOutlineIcon sx={{ fontSize: "50px", color: theme.palette.primary.main }} />
            </Box>
            <Box
              sx={{
                padding: "0% 5% 0% 5%",
                display: "flex",
                flexDirection: "row",
                gap: "1%",
                marginTop: "2%",
              }}
            >
              <Typography variant="h5" align="left" margin="auto">
                <strong>Streamlined Operations for Maximum Productivity:</strong> Clients benefit from the expertise of{" "}
                <CompanyName /> by gaining access to streamlined operations that foster maximum productivity. Our
                proficiency in logistics, HR, and project management ensures that your business processes are optimized
                for efficiency. By entrusting these critical functions to us, you free up valuable time and resources,
                allowing your team to focus on core competencies and strategic initiatives. The result is a well-oiled
                organizational machine that operates with precision, driving productivity to new heights.
              </Typography>
              <DoneOutlineIcon sx={{ fontSize: "50px", color: theme.palette.primary.main }} />
            </Box>
            <Box
              sx={{
                padding: "0% 5% 0% 5%",
                display: "flex",
                flexDirection: "row",
                gap: "1%",
                marginTop: "2%",
              }}
            >
              <Typography variant="h5" align="left" margin="auto">
                <strong>Comprehensive Health and Safety Assurance:</strong> Safety isn't just a priority; it's a
                non-negotiable aspect of business success. Clients choose <CompanyName /> for our unwavering commitment
                to comprehensive health and safety assurance. We go beyond mere compliance, meticulously crafting safety
                protocols that are customized to your industry and specific needs. By partnering with us, clients can
                rest assured that their workplaces are not only compliant with regulations but also foster a culture of
                well-being and security for employees and assets alike.
              </Typography>
              <DoneOutlineIcon sx={{ fontSize: "50px", color: theme.palette.primary.main }} />
            </Box>
            <Box
              sx={{
                padding: "0% 5% 0% 5%",
                display: "flex",
                flexDirection: "row",
                gap: "1%",
                marginTop: "2%",
              }}
            >
              <Typography variant="h5" align="left" margin="auto">
                <strong>Strategic Asset Management for Future Growth:</strong> One of the key reasons clients choose{" "}
                <CompanyName /> is our strategic approach to asset management. We view assets not just as numbers on a
                balance sheet but as crucial investments in your business's future. Our asset management experts employ
                strategic planning, maintenance, and optimization to maximize the value of your assets. By entrusting us
                with this responsibility, clients position their businesses for sustainable growth and long-term
                success, confident that their assets are in capable hands.
              </Typography>
              <DoneOutlineIcon sx={{ fontSize: "50px", color: theme.palette.primary.main }} />
            </Box>
            <Box
              sx={{
                padding: "0% 5% 0% 5%",
                display: "flex",
                flexDirection: "row",
                gap: "1%",
                marginTop: "2%",
              }}
            >
              <Typography variant="h5" align="left" margin="auto">
                <strong>Proactive Property and Building Maintenance:</strong> Property and building maintenance can make
                or break the impression your business leaves on clients and employees. Our clients work with{" "}
                <CompanyName /> because we take a proactive approach to maintain pristine and functional workspaces.
                From routine upkeep to emergency repairs, our expert team ensures that your physical environment
                reflects the professionalism and efficiency of your business. This proactive maintenance not only
                enhances the longevity of your assets but also creates an inviting atmosphere conducive to productivity.
              </Typography>
              <DoneOutlineIcon sx={{ fontSize: "50px", color: theme.palette.primary.main }} />
            </Box>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
