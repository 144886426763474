import React from "react";
import { Container, ThemeProvider, Typography } from "@mui/material";
import { Box, Grid, Button, Divider } from "@mui/material";
import { Link } from "react-router-dom";

import { withStyles } from "@mui/styles";
import theme from "../../theme";
import CompanyName from "../../utils/CompanyName";

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(4),
  },
  container: {
    marginTop: theme.spacing(15),
    marginBottom: theme.spacing(15),
    display: "flex",
    position: "relative",
  },

  item: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(0, 5),
  },
  image: {
    height: 55,
  },
  sideimg: {
    width: "80%",
    height: "80%",
    paddingLeft: 70,
    paddingTop: 40,
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  h6: {
    marginTop: theme.spacing(2),
  },
  para: {
    fontSize: "20px",
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
    textAlign: "justify",
    display: "block",
  },
});

function AboutDescription(props) {
  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="xl" sx={{ marginTop: theme.spacing(4) }}>
        <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
          <Box sx={{ justifyContent: "left", marginBottom: "2%" }}>
            <Divider textAlign="left">
              <Typography align="left" variant="h3">
                About JKC Solutions
              </Typography>
            </Divider>
            <Box
              sx={{
                display: "flex",
                marginBottom: "5%",
                padding: "0% 5% 0% 5%",
                marginTop: "2%",
              }}
            >
              <Grid container spacing={5} direction="column" justifyContent="center" alignItems="center">
                <Grid item xs="auto">
                  <Typography variant="body1">
                    At <CompanyName />, we are not just a business support and facilities management company; we are
                    your dedicated partners in achieving operational excellence. With a commitment to transforming
                    challenges into opportunities, we bring a wealth of experience and innovation to the table. Our
                    journey is rooted in a passion for helping businesses thrive, offering tailored solutions that cater
                    to the unique needs of each client. From logistics to HR, health and safety, project management,
                    property and building maintenance, to strategic asset management – we cover the spectrum of support
                    services, ensuring that your business functions seamlessly and efficiently.
                  </Typography>
                </Grid>
                <Grid item xs="auto">
                  <Typography variant="body1">
                    What sets us apart is our unwavering commitment to three guiding principles – expertise, innovation,
                    and client-centricity. Our team consists of seasoned professionals who bring a wealth of expertise
                    in their respective domains, ensuring that you receive the highest quality service across the board.
                    We thrive on innovation, constantly exploring new technologies and strategies to keep your business
                    at the forefront of industry trends. Above all, our client-centric approach forms the cornerstone of
                    our operations. Your success is our success, and we take pride in building strong, lasting
                    relationships with our clients, understanding their unique needs, and evolving our services to
                    exceed expectations.
                  </Typography>
                </Grid>
                <Grid item xs="auto">
                  <Typography variant="body1">
                    Choosing <CompanyName /> means choosing a partner dedicated to your business's success. Our
                    commitment to excellence, combined with a comprehensive suite of services, positions us as a
                    one-stop solution for all your business support and facilities management needs. We bring not just
                    expertise but a passion for innovation and a relentless pursuit of client satisfaction. Join us on
                    this journey to operational excellence – where efficiency meets innovation, and success becomes a
                    shared reality.
                  </Typography>
                </Grid>
                <Grid item xs="auto">
                  <Typography variant="body1">
                    Welcome to <CompanyName />, your trusted ally in business support and facilities management. Contact
                    us today to begin your journey with confidence and trust.
                  </Typography>
                </Grid>
                <Box sx={{ display: "flex" }}>
                  <Button
                    component={Link}
                    to="/contact"
                    variant="contained"
                    size={"large"}
                    sx={{
                      fontFamily: theme.typography.button,
                      borderRadius: 5,
                      background: "linear-gradient(to right bottom, #29ba74, #87c563)",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    Contact us today
                  </Button>
                </Box>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default withStyles(styles)(AboutDescription);
