import React from "react";
import { Container, ThemeProvider, Typography, Box } from "@mui/material";
import theme from "../theme";

import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";

export default function AppSubtitle(props) {
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: "flex",
          backgroundColor: theme.palette.secondary.dark,
        }}
      >
        <Container
          sx={{
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(4),
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-around", width: "100%" }}>
            <Box sx={{ display: "flex" }}>
              <DoubleArrowIcon sx={{ color: theme.palette.primary.main, paddingTop: "3px" }} />
              <Typography
                variant="subtitle1"
                fontFamily={"Work Sans"}
                fontWeight={700}
                fontSize="16px"
                sx={{ color: "white" }}
              >
                Optimizing
              </Typography>
            </Box>
            <Box sx={{ display: "flex" }}>
              <DoubleArrowIcon sx={{ color: theme.palette.primary.main, paddingTop: "3px" }} />
              <Typography
                variant="subtitle1"
                fontFamily={"Work Sans"}
                fontWeight={700}
                fontSize="16px"
                sx={{ color: "white" }}
              >
                Efficiency
              </Typography>
            </Box>
            <Box sx={{ display: "flex" }}>
              <DoubleArrowIcon sx={{ color: theme.palette.primary.main, alignSelf: "center" }} />
              <Typography
                variant="subtitle1"
                fontFamily={"Work Sans"}
                fontWeight={700}
                fontSize="16px"
                sx={{ color: "white" }}
              >
                Strategic
              </Typography>
            </Box>
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
}

<Typography variant="subtitle1" fontFamily={"Lato"} fontWeight={300} fontSize="20px">
  Taking gardening to the next level.
</Typography>;
