import { MenuItem, Button } from "@mui/material";
import { Link } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../../../theme";

export default function MenuTitle(props) {
  const { id, handleCloseNavMenu, menuLink, title } = props;
  return (
    <ThemeProvider theme={theme}>
      <MenuItem key={id} onClick={handleCloseNavMenu}>
        <Button key={id} component={Link} to={menuLink} onClick={handleCloseNavMenu} sx={{ color: "black" }}>
          {title}
        </Button>
      </MenuItem>
    </ThemeProvider>
  );
}
