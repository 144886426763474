import React from "react";
import { ThemeProvider } from "@mui/material";
import { withStyles } from "@mui/styles";
import themeTerms from "../../themeTerms";
import Banner from "../../utils/Banner";
import AboutDescription from "./AboutDescription";

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(4),
  },
  container: {
    marginTop: theme.spacing(15),
    marginBottom: theme.spacing(30),
    display: "flex",
    position: "relative",
  },

  item: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(0, 5),
  },
  image: {
    height: 55,
  },
  sideimg: {
    width: "80%",
    height: "80%",
    paddingLeft: 70,
    paddingTop: 40,
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  h6: {
    marginTop: theme.spacing(2),
  },
  para: {
    fontSize: "20px",
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
    textAlign: "justify",
    display: "block",
  },
});

function AboutUs(props) {
  const theme = themeTerms;

  return (
    <ThemeProvider theme={theme}>
      <AboutDescription />
    </ThemeProvider>
  );
}

export default withStyles(styles)(AboutUs);
