import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import { Box, Container, Typography, Divider } from "@mui/material";
import theme from "../../theme";
import support from "../../../img/blog/support.jpg";
import facilities from "../../../img/blog/facilities.jpg";
import hr from "../../../img/blog/hr.jpg";
import healthsafety from "../../../img/blog/healthsafety.jpg";
import property from "../../../img/blog/property.jpg";
import asset from "../../../img/blog/asset.jpg";

import BlogCard from "./BlogCard";

const blogs = [
  {
    title: "Efficiency Unleashed: How Business Support Services Can Transform Your Operations",
    image: support,
    link: "/blog/support",
    alt: "support",
    snippet: "In the dynamic landscape of modern business, efficiency is more than just a buzzword...",
  },
  {
    title: "Navigating the Workplace: The Ultimate Guide to Facilities Management Excellence",
    image: facilities,
    link: "/blog/facilities",
    alt: "facilities",
    snippet: "In the modern business landscape, where the workplace is no longer confined to physical offices...",
  },
  {
    title: "Beyond 9 to 5: Elevating Employee Experience Through HR Innovations",
    image: hr,
    link: "/blog/hr",
    alt: "hr",
    snippet:
      "In today's rapidly evolving business landscape, the traditional 9-to-5 workday is becoming increasingly...",
  },
  {
    title: "Safe and Sound: The Comprehensive Approach to Health and Safety in the Workplace",
    image: healthsafety,
    link: "/blog/healthandsafety",
    alt: "healthandsafety",
    snippet:
      "In today's fast-paced work environment, ensuring the health and safety of employees has never been more...",
  },
  {
    title: "Mastering the Workspace Symphony: Property and Building Maintenance Secrets Revealed",
    image: property,
    link: "/blog/property",
    alt: "property",
    snippet:
      "In the grand orchestration of a successful business, the physical workspace serves as the stage where the...",
  },
  {
    title: "Assets 360: Strategic Asset Management for Future-Ready Businesses",
    image: asset,
    link: "/blog/assets",
    alt: "asset",
    snippet: "In the ever-evolving landscape of modern business, where change is constant and competition fierce... ",
  },
];

export default function Blog(props) {
  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="lg" sx={{ marginTop: theme.spacing(4) }}>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            marginBottom: "2%",
          }}
        >
          <Box>
            <Divider textAlign="left">
              <Typography align="left" variant="h3">
                Articles
              </Typography>
            </Divider>
          </Box>
          <Box>
            <Box
              sx={{
                display: "flex",
                flexGrow: 1,
                flexWrap: "wrap",
                justifyContent: "center",
                gap: "1em",
                marginTop: "2%",
              }}
            >
              {blogs.map((blog, index) => (
                <BlogCard data={blog} key={index} size={200} />
              ))}
            </Box>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
