import React, { useState, useEffect } from "react";
import { Container, Typography, Divider } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { Box } from "@mui/material";
import CompanyName from "../../utils/CompanyName";
import Sectors from "./Sectors";
import logistics from "../../../img/categories/logistics.jpg";
import hr from "../../../img/categories/hr.jpg";
import asset from "../../../img/categories/asset.jpg";
import maintenance from "../../../img/categories/maintenance.jpg";
import healthandsafety from "../../../img/categories/healthandsafety.jpg";
import projectmanagement from "../../../img/categories/projectmanagement.jpg";

import theme from "../../theme";

const sectors = [
  {
    title: "Logistics",
    image: logistics,
    joinus: <ul style={{ listStyleType: "none", padding: 0 }}></ul>,
    text: (
      <>
        <p>
          Welcome to the Logistics Department at <CompanyName />, where precision meets efficiency in the seamless
          orchestration of supply chain operations. As a cornerstone of our comprehensive business support and
          facilities management services, our logistics department is dedicated to ensuring that your business operates
          like a well-oiled machine, with every component of your supply chain working in perfect harmony.
        </p>
        <p>
          At the heart of our logistics department lies a team of seasoned professionals who bring a wealth of
          experience and expertise to the table. With a meticulous attention to detail and a deep understanding of
          industry best practices, our logistics experts navigate the complexities of supply chain management with
          finesse, ensuring that your goods flow seamlessly from point A to point B.{" "}
        </p>
        <p>
          From inventory management and warehousing to transportation and distribution, our logistics department offers
          a comprehensive suite of services designed to optimize every aspect of your supply chain. Whether you're a
          small startup or a large enterprise, we tailor our solutions to meet your unique needs, providing scalable and
          cost-effective logistics solutions that drive efficiency and maximize profitability.
        </p>
        <p>
          {" "}
          What sets our logistics department apart is our commitment to innovation. We leverage cutting-edge
          technologies and industry-leading practices to streamline processes, reduce costs, and enhance visibility
          throughout your supply chain. With state-of-the-art tracking systems and real-time data analytics, you can
          trust that your goods are in safe hands every step of the way.{" "}
        </p>
        <p>
          {" "}
          Above all, our logistics department is driven by a relentless dedication to client satisfaction. We understand
          that the success of your business hinges on the efficiency and reliability of your supply chain, and we go
          above and beyond to ensure that your expectations are not just met but exceeded. With <CompanyName />
          's logistics department by your side, you can rest assured that your supply chain is in the best possible
          hands, allowing you to focus on what you do best – growing your business and serving your customers.{" "}
        </p>{" "}
        <p>
          Our commitment to excellence extends beyond the logistics department. As part of our holistic approach to
          business support and facilities management, we integrate our logistics solutions seamlessly with our other
          services, providing you with a comprehensive suite of support that covers every aspect of your operations.
          From HR and health and safety to property maintenance and asset management, we offer a one-stop solution for
          all your business needs, allowing you to streamline processes, reduce costs, and drive growth.
        </p>{" "}
        <p>
          In today's fast-paced business environment, a reliable and efficient supply chain is essential for success.
          With <CompanyName />
          's logistics department, you can trust that your supply chain is in the hands of experts who are committed to
          delivering results. Whether you're looking to streamline processes, reduce costs, or enhance visibility, we
          have the solutions you need to take your business to the next level. Contact us today to learn more about how
          our logistics department can transform your operations and drive success.
        </p>
      </>
    ),
  },
  {
    title: "Human Resources",
    image: hr,
    joinus: <ul style={{ listStyleType: "none", padding: 0 }}></ul>,
    text: (
      <>
        <p>
          Welcome to the Human Resources Department at <CompanyName />, the driving force behind fostering a workplace
          environment where every individual thrives. We understand that the success of any organization hinges on the
          strength and happiness of its workforce, which is why our HR department is dedicated to providing
          comprehensive support and guidance to both employees and management alike.
        </p>
        <p>
          Our HR department serves as the backbone of your organization's people strategy, aligning HR initiatives with
          your business objectives to drive growth and success. From strategic workforce planning to talent management
          and succession planning, we partner with you to ensure that your human capital strategy is closely aligned
          with your long-term business goals.
        </p>
        <p>
          One of the key focuses of our HR department is talent development and retention. We believe in investing in
          the professional growth and development of your employees, not only to enhance their skills and capabilities
          but also to foster a culture of loyalty and commitment. Our HR specialists work closely with managers to
          identify training and development opportunities, providing employees with the tools and resources they need to
          succeed.
        </p>
        <p>
          In addition to talent development, our HR department is also committed to creating a positive and supportive
          work environment where employees feel valued, respected, and empowered. We understand the importance of
          employee engagement and morale in driving productivity and performance, which is why we offer a range of
          programs and initiatives designed to enhance employee satisfaction and well-being.
        </p>
        <p>
          At <CompanyName />, we recognize that every organization is unique, and that's why our HR department takes a
          personalized approach to serving our clients. Whether you're a small startup or a large corporation, we take
          the time to understand your organization's culture, values, and goals, tailoring our HR solutions to meet your
          specific needs.
        </p>
        <p>
          In today's competitive business landscape, your people are your greatest asset. With <CompanyName />
          's HR department by your side, you can unlock the full potential of your workforce and create a workplace
          where every individual thrives. Contact us today to learn more about how our HR department can support your
          organization's growth and success.
        </p>
      </>
    ),
  },
  {
    title: "Health and Safety and Risk Management",
    image: healthandsafety,
    joinus: <ul style={{ listStyleType: "none", padding: 0 }}></ul>,
    text: (
      <>
        <p>
          Welcome to the Health and Safety and Risk Management Department at <CompanyName />, where we prioritize the
          well-being and security of your employees and assets above all else. As an integral part of our comprehensive
          business support and facilities management services, our department is dedicated to ensuring that your
          workplace is safe, compliant, and prepared to mitigate risks effectively.
        </p>
        <p>
          At the core of our Health and Safety and Risk Management Department is a team of highly skilled professionals
          who bring a wealth of experience and expertise in occupational health and safety, compliance, and risk
          assessment. Our specialists are committed to staying abreast of the latest regulations and industry best
          practices to provide you with the highest level of service and support.
        </p>
        <p>
          Our department offers a wide range of services designed to address all aspects of health, safety, and risk
          management within your organization. From conducting comprehensive risk assessments and developing tailored
          safety programs to implementing emergency response protocols and providing ongoing training and support, we
          work tirelessly to ensure that your workplace is a secure and healthy environment for all.
        </p>
        <p>
          We understand that the health and safety of your employees are paramount, which is why our department takes a
          proactive approach to identify and mitigate potential risks before they escalate. Whether it's implementing
          ergonomic solutions to prevent workplace injuries or conducting regular safety audits to identify hazards, we
          are committed to helping you create a culture of safety and well-being within your organization.
        </p>
        <p>
          In addition to protecting your employees, our Health and Safety and Risk Management Department also focuses on
          safeguarding your assets and minimizing operational risks. We work closely with your leadership team to
          develop and implement risk management strategies that protect your business from potential threats, ensuring
          continuity and resilience in the face of unforeseen challenges.
        </p>
        <p>
          At <CompanyName />, we understand that health, safety, and risk management are critical components of any
          successful business operation. With our dedicated Health and Safety and Risk Management Department by your
          side, you can rest assured that your workplace is in safe hands. Contact us today to learn more about how our
          department can support your organization's health, safety, and risk management needs and help you achieve
          peace of mind.
        </p>
      </>
    ),
  },
  {
    title: "Project Management and Business Continuity",
    image: projectmanagement,
    joinus: <ul style={{ listStyleType: "none", padding: 0 }}></ul>,
    text: (
      <>
        <p>
          Welcome to the Project Management Department at <CompanyName />, where precision meets innovation in the art
          of bringing projects to fruition. As an integral pillar of our comprehensive business support and facilities
          management services, our department stands at the forefront of orchestrating seamless project execution, from
          conception to completion.
        </p>
        <p>
          At the heart of our Project Management Department lies a team of dedicated professionals, each a master of
          their craft in the realm of project planning, coordination, and execution. With a wealth of experience and a
          passion for excellence, our project managers are adept at navigating the complexities of diverse projects,
          ensuring that every detail is meticulously attended to and every milestone is achieved with precision.
        </p>
        <p>
          Our department offers a comprehensive suite of project management services tailored to meet the unique needs
          and objectives of our clients. Whether it's overseeing construction projects, orchestrating office
          relocations, implementing IT infrastructure upgrades, or managing event logistics, we possess the expertise
          and agility to handle projects of any size and scope.
        </p>
        <p>
          What sets our Project Management Department apart is our commitment to innovation and adaptability. We
          leverage cutting-edge project management methodologies, tools, and technologies to streamline processes,
          enhance collaboration, and drive efficiency. From agile project management frameworks to advanced project
          tracking and reporting systems, we harness the power of innovation to deliver results that exceed
          expectations.
        </p>
        <p>
          Effective communication and collaboration are the cornerstones of successful project management, and our
          department excels in fostering a culture of transparency, teamwork, and accountability. We maintain open lines
          of communication with clients, stakeholders, and project teams, providing regular progress updates, soliciting
          feedback, and ensuring alignment every step of the way.
        </p>
        <p>
          In addition to managing project timelines and budgets, our department is also focused on mitigating risks and
          overcoming challenges to ensure project success. We conduct comprehensive risk assessments, develop
          contingency plans, and proactively address issues as they arise, allowing us to navigate obstacles with
          agility and minimize disruptions to project delivery.
        </p>
        <p>
          At <CompanyName />, we understand that successful project management is essential for achieving business
          objectives and driving growth. With our dedicated Project Management Department at your service, you can trust
          that your projects are in expert hands, and that we are committed to delivering results that propel your
          organization forward. Contact us today to learn more about how our department can support your project
          management needs and help you turn your visions into reality.
        </p>
      </>
    ),
  },
  {
    title: "Property and Building Maintenance",
    image: maintenance,
    joinus: <ul style={{ listStyleType: "none", padding: 0 }}></ul>,
    text: (
      <>
        <p>
          Welcome to the Property and Building Maintenance Department at <CompanyName />, where we take pride in
          ensuring that your physical environment reflects the professionalism and efficiency of your business. As an
          integral part of our comprehensive business support and facilities management services, our department is
          dedicated to maintaining, enhancing, and optimizing your property and building infrastructure to support your
          business objectives.
        </p>
        <p>
          At the heart of our Property and Building Maintenance Department is a team of skilled professionals who bring
          a wealth of experience and expertise in property management, building maintenance, and facility operations.
          With a keen eye for detail and a commitment to excellence, our specialists work tirelessly to ensure that your
          property remains in top condition, providing a safe, comfortable, and productive environment for your
          employees and clients alike.
        </p>
        <p>
          Our department offers a wide range of services designed to address all aspects of property and building
          maintenance, from routine upkeep to emergency repairs and renovations. Whether it's HVAC maintenance, plumbing
          repairs, electrical services, or landscaping and groundskeeping, we have the knowledge, skills, and resources
          to handle any maintenance challenge that comes our way.
        </p>
        <p>
          We understand that every property is unique, which is why our Property and Building Maintenance Department
          takes a customized approach to serving our clients. Whether you're a commercial office building, a retail
          storefront, or a residential complex, we tailor our maintenance solutions to meet your specific needs,
          ensuring that your property remains in pristine condition year-round.
        </p>
        <p>
          In addition to routine maintenance, our department also focuses on long-term asset preservation and
          enhancement. We work closely with property owners and managers to develop strategic maintenance plans that
          optimize the lifespan of building systems and components, minimize operating costs, and enhance property value
          over time.
        </p>
        <p>
          At <CompanyName />, we understand that your property is a significant investment, and we are committed to
          helping you protect and maximize its value. With our dedicated Property and Building Maintenance Department by
          your side, you can trust that your property is in the hands of experts who are committed to delivering
          results. Contact us today to learn more about how our department can support your property maintenance needs
          and help you achieve your business goals.
        </p>
      </>
    ),
  },

  {
    title: "Asset Management",
    image: asset,
    joinus: <ul style={{ listStyleType: "none", padding: 0 }}></ul>,
    text: (
      <>
        <p>
          Welcome to the Asset Management Department at <CompanyName />, where we specialize in maximizing the value and
          performance of your assets to drive long-term success. As an integral component of our comprehensive business
          support and facilities management services, our department is dedicated to helping you strategically manage
          and optimize your assets to achieve your business goals.
        </p>
        <p>
          At the heart of our Asset Management Department is a team of experienced professionals who bring a wealth of
          expertise in asset management, financial analysis, and strategic planning. With a deep understanding of
          industry best practices and a commitment to delivering results, our specialists work closely with you to
          develop and implement customized asset management strategies tailored to your unique needs and objectives.
        </p>
        <p>
          Our department offers a wide range of asset management services designed to help you effectively manage and
          optimize your assets throughout their lifecycle. Whether it's financial analysis, portfolio management, risk
          assessment, or performance monitoring, we provide the tools, resources, and expertise you need to make
          informed decisions and maximize the value of your assets.
        </p>
        <p>
          We understand that every organization has unique asset management needs, which is why our Asset Management
          Department takes a personalized approach to serving our clients. Whether you're a small business with a
          handful of assets or a large corporation with a diverse portfolio, we take the time to understand your
          specific requirements and develop customized solutions that align with your business objectives.
        </p>
        <p>
          In addition to maximizing asset value, our department also focuses on mitigating risk and ensuring compliance
          with regulatory requirements. We conduct thorough risk assessments and develop comprehensive risk management
          strategies to protect your assets and minimize potential liabilities, helping you achieve peace of mind and
          confidence in your asset management practices.
        </p>
        <p>
          At <CompanyName />, we understand that your assets are valuable resources that drive your business forward.
          With our dedicated Asset Management Department by your side, you can trust that your assets are in expert
          hands and that we are committed to helping you achieve your business objectives. Contact us today to learn
          more about how our department can support your asset management needs and help you unlock the full potential
          of your assets.
        </p>
      </>
    ),
  },
];

export default function SectorPage() {
  const [title, setTitle] = useState("");
  const [image, setImage] = useState("");
  const [text, setText] = useState("");
  const [text2, setText2] = useState("");
  const fullurl = window.location.href;

  useEffect(() => {
    const url = fullurl.split("sector/")[1];
    if (url === "logistics") {
      setTitle(sectors[0].title);
      setImage(sectors[0].image);
      setText(sectors[0].text);
      setText2(sectors[0].joinus);
    }
    if (url === "hr") {
      setTitle(sectors[1].title);
      setImage(sectors[1].image);
      setText(sectors[1].text);
      setText2(sectors[1].joinus);
    }
    if (url === "healthandsafety") {
      setTitle(sectors[2].title);
      setImage(sectors[2].image);
      setText(sectors[2].text);
      setText2(sectors[2].joinus);
    }
    if (url === "projectmanagement") {
      setTitle(sectors[3].title);
      setImage(sectors[3].image);
      setText(sectors[3].text);
      setText2(sectors[3].joinus);
    }
    if (url === "maintenance") {
      setTitle(sectors[4].title);
      setImage(sectors[4].image);
      setText(sectors[4].text);
      setText2(sectors[4].joinus);
    }
    if (url === "assets") {
      setTitle(sectors[5].title);
      setImage(sectors[5].image);
      setText(sectors[5].text);
      setText2(sectors[5].joinus);
    }
  }, [fullurl]);

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth={false}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            marginTop: "5%",
            padding: "0% 5% 0% 5%",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box
              component="img"
              alt="SectorImage"
              src={image}
              sx={{
                height: "auto",
                maxWidth: "100%",
                padding: "2%",
              }}
            />
          </Box>
          <Box sx={{ margin: "2%" }}>
            <Typography variant="h5" textAlign="justify" display="block">
              {text2}
            </Typography>
            <Typography variant="body1" textAlign="justify" display="block">
              {text}
            </Typography>
          </Box>
          <Sectors />
        </Box>
      </Container>
    </ThemeProvider>
  );
}
