import React from "react";
import { Container, Typography, Box, Button } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { Link } from "react-router-dom";
import theme from "../../theme";
import "./LandingPage.css";

export default function LandingPage(props) {
  return (
    <ThemeProvider theme={theme}>
      <Container
        maxWidth={false}
        style={{ padding: "0px 0px 0p 0px", minHeight: "80vh", display: "flex", justifyContent: "left" }}
      >
        <div className="bg"></div>

        <Box
          sx={{
            alignItems: "left",
            display: "flex",
            flexDirection: "column",
            marginLeft: "4%",
            height: "100%",
            gap: "6em",
          }}
        >
          <Box>
            <Typography variant="h1" align="left" sx={{ maxWidth: "22.4em", marginTop: "10%" }}>
              Smart Business, Brilliant Future.
            </Typography>
            <Typography
              variant="h6"
              align="left"
              sx={{ maxWidth: "22.4em", marginTop: theme.spacing(3), color: theme.palette.primary.main }}
            >
              Seamless Business Support and Facilities Management.
            </Typography>
          </Box>
          <Box>
            <Button
              component={Link}
              to="/contact"
              variant="contained"
              size={"small"}
              sx={{
                fontFamily: theme.typography.button,
                borderRadius: 4,
                // background: theme.palette.primary.main,
                background: "linear-gradient(to right bottom, #29ba74, #87c563)",
                alignItems: "center",
              }}
            >
              Contact us
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
