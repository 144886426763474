import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import { Box, Container, Typography, Divider, Button } from "@mui/material";
import { Link } from "react-router-dom";
import imageone from "../../img/imageone.jpg";
import CompanyName from "../utils/CompanyName";

import theme from "../theme";
export default function CompanyDescription(props) {
  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="xl" sx={{ marginTop: theme.spacing(4) }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1%",
          }}
        >
          <Box>
            <Divider textAlign="left">
              <Typography align="left" variant="h3">
                About us
              </Typography>
            </Divider>
          </Box>
          <Box
            sx={{
              padding: "0% 5% 0% 5%",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              marginTop: "2%",
              gap: "2%",
            }}
          >
            <Box
              sx={{
                maxWidth: "100%",
                width: "50%",
                display: "flex",
                flexGrow: 2,
                flexDirection: "column",
                justifyContent: "space-between",
                paddingBottom: "10px",
                gap: "10px",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <Typography variant="h5" align="left">
                  Welcome to <CompanyName />, where excellence meets efficiency in a symphony of comprehensive business
                  support services. As pioneers in the realm of corporate facilitation, we pride ourselves on being the
                  backbone that allows businesses to thrive. With a multifaceted approach, we navigate the intricacies
                  of logistics, streamline HR processes, ensure top-notch health and safety standards, master the art of
                  project management, and excel in property and building maintenance and asset management.
                </Typography>
              </Box>
              <Box sx={{ display: "flex" }}>
                <Button
                  component={Link}
                  to="/about"
                  variant="contained"
                  size={"large"}
                  sx={{
                    fontFamily: theme.typography.button,
                    borderRadius: 5,
                    // background: theme.palette.primary.main,
                    background: "linear-gradient(to right bottom, #29ba74, #87c563)",
                    alignItems: "center",
                    width: "50%",
                  }}
                >
                  Read more
                </Button>
              </Box>
            </Box>
            <Box
              component="img"
              alt="Hiring"
              src={imageone}
              sx={{
                maxWidth: "100%",
                width: "500px",
                display: "flex",
                borderRadius: "10px",
              }}
            />
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
