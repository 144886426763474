import React from "react";
import { Grid, Container } from "@mui/material";
import { Typography, ThemeProvider } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import CompanyName from "../utils/CompanyName";
import theme from "../theme";

function Copyright() {
  return (
    <React.Fragment>
      {"© "}
      <CompanyName /> {new Date().getFullYear()}
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: theme.palette.secondary.main,
  },
  container: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    display: "flex",
  },
  iconsWrapper: {
    height: 120,
  },
  icons: {
    display: "flex",
  },
  icon: {
    width: 48,
    height: 48,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.warning.main,
    marginRight: theme.spacing(1),
    "&:hover": {
      backgroundColor: theme.palette.warning.dark,
    },
  },
  list: {
    margin: 0,
    listStyle: "none",
    padding: 0,
  },
  listItem: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  item: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(0, 5),
    color: "white",
  },
}));

export default function AppFooter() {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <Typography component="footer" className={classes.root}>
        <Container className={classes.container}>
          <Grid container spacing={5}>
            <Grid item xs={12} md={4}>
              <div className={classes.item}>
                <Typography variant="caption">
                  <Copyright />
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className={classes.item}>
                <Link style={{ color: "white" }} to={"/terms"}>
                  Terms
                </Link>
                <Link style={{ color: "white" }} to={"/privacy"}>
                  Privacy
                </Link>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className={classes.item}>
                <Typography variant="caption">{"All rights are reserved"}</Typography>
              </div>
            </Grid>
          </Grid>
        </Container>
      </Typography>
    </ThemeProvider>
  );
}
