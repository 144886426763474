import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../src/modules/theme";

import NavBar from "./modules/views/navigation/NavBar";
import ClientsWhy from "./modules/views/clients/Clientswhy";
import CompanyDescription from "./modules/views/CompanyDescription";
import LandingPage from "./modules/views/landing_page/LandingPage";
import SectorPage from "./modules/views/sectors/SectorPage";
import ScrollToTop from "./modules/components/ScrollToTop";
import AboutUs from "./modules/views/about_us/AboutUs";
import Sectors from "./modules/views/sectors/Sectors";
import Contact from "./modules/views/contact/Contact";
import BlogPage from "./modules/views/blog/BlogPage";
import AppFooter from "./modules/views/AppFooter";
import Privacy from "./modules/views/Privacy";
import Blog from "./modules/views/blog/Blog";
import Terms from "./modules/views/Terms";
import withRoot from "./modules/withRoot";
import AppSubtitle from "./modules/views/AppSubtitle";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <ScrollToTop>
          <Routes>
            <Route exact path="/" element={<Index />} />
            <Route path="/about" element={<AboutView />} />
            <Route path="/contact" element={<ContactView />} />
            <Route path="/solutions" element={<SolutionsView />} />
            <Route path="/terms" element={<TermsView />} />
            <Route path="/privacy" element={<PrivacyView />} />
            <Route path="/blog" element={<BlogView />} />
            <Route path="/blog/support" element={<BlogView />} />
            <Route path="/blog/facilities" element={<BlogView />} />
            <Route path="/blog/hr" element={<BlogView />} />
            <Route path="/blog/healthandsafety" element={<BlogView />} />
            <Route path="/blog/property" element={<BlogView />} />
            <Route path="/blog/assets" element={<BlogView />} />
            <Route path="/sector/logistics" element={<SectorView />} />
            <Route path="/sector/hr" element={<SectorView />} />
            <Route path="/sector/healthandsafety" element={<SectorView />} />
            <Route path="/sector/projectmanagement" element={<SectorView />} />
            <Route path="/sector/maintenance" element={<SectorView />} />
            <Route path="/sector/assets" element={<SectorView />} />
            <Route exact path="/" element={<Index />} />
          </Routes>
        </ScrollToTop>
      </Router>
    </ThemeProvider>
  );
}

function Index() {
  return (
    <React.Fragment>
      <NavBar />
      <LandingPage />
      <AppSubtitle />
      <CompanyDescription />
      <Sectors />
      <ClientsWhy />
      <Blog />
      <AppFooter />
    </React.Fragment>
  );
}

function AboutView() {
  return (
    <React.Fragment>
      <NavBar />
      <AboutUs />
      <AppFooter />
    </React.Fragment>
  );
}

function PrivacyView() {
  return (
    <React.Fragment>
      <NavBar />
      <Privacy />
      <AppFooter />
    </React.Fragment>
  );
}

function TermsView() {
  return (
    <React.Fragment>
      <NavBar />
      <Terms />
      <AppFooter />
    </React.Fragment>
  );
}

function ContactView() {
  return (
    <React.Fragment>
      <NavBar />
      <Contact />
      <AppFooter />
    </React.Fragment>
  );
}

function SolutionsView() {
  return (
    <React.Fragment>
      <NavBar />
      <Sectors />
      <AppFooter />
    </React.Fragment>
  );
}

function BlogView() {
  return (
    <React.Fragment>
      <NavBar />
      <BlogPage />
      <AppFooter />
    </React.Fragment>
  );
}

function SectorView() {
  return (
    <React.Fragment>
      <NavBar />
      <SectorPage />
      <AppFooter />
    </React.Fragment>
  );
}

export default withRoot(App);
