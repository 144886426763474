import React from "react";
import { Box, Container, IconButton, Typography } from "@mui/material";
import { Menu, Toolbar } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { Link } from "react-router-dom";

import AppBar from "@mui/material/AppBar";
import MenuIcon from "@mui/icons-material/Menu";

import MenuTitle from "./widgets/MenuTitles";
import theme from "../../theme";
import logo from "../../../img/logo/logo.png";

const pages = [
  {
    id: 1,
    title: "Home",
    link: "/",
  },
  {
    id: 2,
    title: "About",
    link: "/about",
  },
  {
    id: 3,
    title: "Contact",
    link: "/contact",
  },
  {
    id: 3,
    title: "Solutions",
    link: "/solutions",
  },
];

export default function NavBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth={false}>
        <AppBar position="sticky" style={{ background: "#f2f2f2", boxShadow: "none" }}>
          <Toolbar variant="dense" disableGutters sx={{ justifyContent: "space-between" }}>
            <Box component={Link} to="/">
              <Box
                sx={{ display: "flex", alignItems: "center" }}
                component="img"
                alt="JKC Solutions logo"
                src={logo}
                style={{ alignItems: "center", marginLeft: "15px" }}
              />
            </Box>
            <Box
              sx={{
                display: { xs: "flex", md: "none", justifyContent: "right" },
              }}
            >
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none", justifyContent: "right" },
                }}
              >
                {pages.map((page) => (
                  <MenuTitle
                    id={page.id}
                    handleCloseNavMenu={handleCloseNavMenu}
                    menuLink={page.link}
                    title={page.title}
                  />
                ))}
              </Menu>
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex", justifyContent: "right" } }}>
              {pages.map((page) => (
                <Typography
                  variant="subtitle1"
                  noWrap
                  component={Link}
                  fontFamily={"Work Sans"}
                  fontWeight={700}
                  fontSize="16px"
                  to={page.link}
                  sx={{ textDecoration: "none", boxShadow: "none", padding: "10px", color: theme.palette.primary.dark }}
                >
                  {page.title}
                </Typography>
              ))}
            </Box>
          </Toolbar>
        </AppBar>
      </Container>
    </ThemeProvider>
  );
}
