import React, { useState, useEffect } from "react";
import { Container, Typography, Divider } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { Box } from "@mui/material";

import theme from "../../theme";

import support from "../../../img/blog/support.jpg";
import facilities from "../../../img/blog/facilities.jpg";
import hr from "../../../img/blog/hr.jpg";
import healthsafety from "../../../img/blog/healthsafety.jpg";
import property from "../../../img/blog/property.jpg";
import asset from "../../../img/blog/asset.jpg";

const blogs = [
  {
    title: "Efficiency Unleashed: How Business Support Services Can Transform Your Operations",
    image: support,
    text: (
      <>
        <p>
          In the dynamic landscape of modern business, efficiency is more than just a buzzword; it's a fundamental
          ingredient for success. Today, businesses face a myriad of challenges, from fierce competition to rapidly
          evolving market trends and technological advancements. In this environment, the ability to streamline
          operations, optimize processes, and maximize productivity has never been more critical. This is where business
          support services come into play, serving as the catalysts for transformation and driving efficiency to new
          heights.
        </p>
        <p>
          At the heart of business support services lies a commitment to understanding and addressing the unique needs
          and challenges of each client. Whether it's logistics, HR, health and safety, project management, property and
          building maintenance, or asset management, these services offer tailored solutions that are precisely aligned
          with the goals and objectives of the organization. By leveraging specialized expertise and industry best
          practices, businesses can unlock new levels of efficiency across every facet of their operations.
        </p>
        <p>
          One of the primary ways in which business support services transform operations is through streamlined
          processes and optimized workflows. By identifying inefficiencies and bottlenecks, these services implement
          strategies and solutions that eliminate waste, reduce redundancies, and improve overall productivity. From
          automating routine tasks to implementing lean methodologies, businesses can experience significant time and
          cost savings, allowing them to focus their resources on strategic initiatives and value-added activities.
        </p>
        <p>
          Moreover, business support services play a crucial role in enhancing organizational agility and
          responsiveness. In today's fast-paced business environment, the ability to adapt quickly to changing market
          conditions and customer demands is essential for survival. Business support services provide the flexibility
          and scalability needed to respond to evolving needs, whether it's scaling up operations to meet increased
          demand or pivoting strategies in response to market shifts. By leveraging agile frameworks and innovative
          technologies, businesses can stay ahead of the curve and capitalize on emerging opportunities.
        </p>
        <p>
          Furthermore, business support services contribute to creating a culture of continuous improvement within
          organizations. By fostering collaboration, innovation, and a commitment to excellence, these services empower
          employees to take ownership of their roles and contribute to the success of the organization. From providing
          training and development opportunities to promoting employee engagement and empowerment, business support
          services cultivate a workforce that is motivated, productive, and aligned with the company's goals.
        </p>
        <p>
          In conclusion, efficiency unleashed through business support services is not merely a goal; it's a journey
          towards organizational excellence and sustainable growth. By partnering with experts who understand the
          intricacies of modern business operations, organizations can unlock new levels of efficiency, agility, and
          innovation. From optimizing processes and workflows to fostering a culture of continuous improvement, business
          support services are the driving force behind transformative change. In a world where efficiency is the
          currency of success, businesses that embrace these services are poised to thrive and prosper in the years to
          come.
        </p>
      </>
    ),
  },
  {
    title: "Navigating the Workplace: The Ultimate Guide to Facilities Management Excellence",
    image: facilities,
    text: (
      <>
        <p>
          In the modern business landscape, where the workplace is no longer confined to physical offices but extends to
          remote and hybrid setups, effective facilities management has never been more critical. From ensuring the
          safety and well-being of employees to optimizing workspace utilization and enhancing the overall employee
          experience, facilities management plays a pivotal role in shaping the success of organizations. In this
          article, we delve into the fundamentals of facilities management and explore how organizations can navigate
          the complexities of the workplace to achieve excellence in facilities management.
        </p>
        <p>
          At its core, facilities management encompasses a broad spectrum of activities aimed at creating and
          maintaining a safe, efficient, and productive work environment. From managing office spaces and facilities to
          overseeing maintenance and repairs, facilities managers are tasked with ensuring that the physical
          infrastructure supports the needs and objectives of the organization. Moreover, facilities management extends
          beyond the physical workspace to encompass aspects such as security, health and safety, sustainability, and
          employee well-being, making it a multifaceted discipline that requires a holistic approach.
        </p>
        <p>
          One of the key pillars of facilities management excellence is strategic planning and optimization. By aligning
          facilities management strategies with business objectives and priorities, organizations can maximize the value
          of their physical assets and resources. This includes optimizing space utilization to accommodate evolving
          workforce needs, implementing energy-efficient practices to reduce costs and environmental impact, and
          leveraging technology to enhance operational efficiency and productivity. By taking a proactive and strategic
          approach to facilities management, organizations can create workspaces that not only meet the needs of
          employees but also contribute to the overall success of the business.
        </p>
        <p>
          Furthermore, facilities management excellence is contingent upon effective communication and collaboration
          across all levels of the organization. Facilities managers must work closely with various stakeholders,
          including employees, management, vendors, and service providers, to ensure that facilities-related initiatives
          are aligned with organizational goals and objectives. By fostering open lines of communication, soliciting
          feedback, and addressing concerns in a timely manner, facilities managers can build trust and confidence among
          stakeholders, leading to greater cooperation and support for facilities management initiatives.
        </p>
        <p>
          Moreover, facilities management excellence requires a commitment to continuous improvement and innovation. In
          a rapidly evolving business landscape, organizations must adapt to changing needs and trends to remain
          competitive and resilient. Facilities managers should embrace emerging technologies, best practices, and
          industry standards to drive innovation and enhance the effectiveness of facilities management operations.
          Whether it's implementing smart building solutions to improve energy efficiency, adopting agile work
          methodologies to accommodate remote and hybrid work arrangements, or incorporating wellness initiatives to
          promote employee health and productivity, facilities managers play a crucial role in shaping the future of the
          workplace.
        </p>
        <p>
          In conclusion, navigating the complexities of the workplace to achieve facilities management excellence
          requires a strategic, collaborative, and innovative approach. By aligning facilities management strategies
          with organizational goals, fostering communication and collaboration, and embracing continuous improvement and
          innovation, organizations can create workspaces that are safe, efficient, and conducive to success. In an era
          where the workplace is constantly evolving, facilities management excellence is not just a goal but a journey
          towards creating work environments that inspire creativity, collaboration, and growth.
        </p>
      </>
    ),
  },
  {
    title: "Beyond 9 to 5: Elevating Employee Experience Through HR Innovations",
    image: hr,
    text: (
      <>
        <p>
          {" "}
          In today's rapidly evolving business landscape, the traditional 9-to-5 workday is becoming increasingly
          obsolete. As organizations strive to attract and retain top talent in a competitive market, they are
          reimagining the employee experience to meet the evolving needs and expectations of today's workforce. At the
          forefront of this transformation are Human Resources (HR) professionals, who are leveraging innovative
          strategies and technologies to create a workplace culture that prioritizes employee well-being, engagement,
          and growth.
        </p>
        <p>
          One of the key drivers of HR innovation is the recognition that employees are the most valuable asset of any
          organization. By focusing on enhancing the employee experience, HR professionals can create a work environment
          where employees feel valued, empowered, and motivated to contribute their best work. This involves
          implementing policies and practices that promote work-life balance, flexibility, and autonomy, allowing
          employees to thrive both personally and professionally.
        </p>
        <p>
          One innovative approach to elevating the employee experience is through the implementation of flexible work
          arrangements. Whether it's remote work, flexible hours, or compressed workweeks, these arrangements give
          employees the freedom to manage their work schedules in a way that best suits their individual needs and
          preferences. By offering flexibility, organizations can improve employee satisfaction, productivity, and
          retention, while also attracting top talent from diverse backgrounds and geographical locations.
        </p>
        <p>
          Another area where HR innovation is making a significant impact is in the realm of employee development and
          career advancement. Today's employees are looking for more than just a paycheck; they want opportunities for
          growth, learning, and advancement within their organizations. HR professionals are responding to this demand
          by implementing innovative training and development programs, mentorship initiatives, and career pathing tools
          that empower employees to take ownership of their professional development and chart their own career
          trajectories.
        </p>
        <p>
          Moreover, HR innovation extends beyond traditional HR functions to encompass areas such as employee wellness
          and engagement. Recognizing the importance of employee well-being in driving organizational success, HR
          professionals are implementing holistic wellness programs that address the physical, mental, and emotional
          health of employees. From mindfulness and meditation programs to fitness challenges and nutrition workshops,
          these initiatives help employees feel supported and valued, leading to greater engagement, productivity, and
          overall job satisfaction.
        </p>
        <p>
          Furthermore, HR innovation is driving the adoption of technology solutions that streamline HR processes and
          enhance the employee experience. From automated onboarding and performance management systems to AI-powered
          recruiting tools and employee self-service portals, these technologies make it easier for HR professionals to
          manage administrative tasks and provide employees with the resources and support they need to succeed.
        </p>
        <p>
          In conclusion, HR innovation is revolutionizing the way organizations approach employee experience, moving
          beyond the traditional 9-to-5 paradigm to create a workplace culture that is flexible, inclusive, and
          empowering. By leveraging innovative strategies, technologies, and practices, HR professionals can elevate the
          employee experience, driving engagement, retention, and organizational success. In an era where the war for
          talent is fiercer than ever, organizations that prioritize HR innovation are better positioned to attract,
          retain, and develop top talent, driving growth and innovation in the years to come.
        </p>
      </>
    ),
  },
  {
    title: "Safe and Sound: The Comprehensive Approach to Health and Safety in the Workplace",
    image: healthsafety,
    text: (
      <>
        <p>
          In today's fast-paced work environment, ensuring the health and safety of employees has never been more
          critical. A comprehensive approach to health and safety not only protects employees from harm but also
          contributes to a positive work culture, enhances productivity, and fosters organizational success. From
          identifying hazards to implementing preventive measures and promoting a culture of safety, organizations must
          take a proactive stance to create a safe and sound workplace for all.
        </p>
        <p>
          At the heart of a comprehensive health and safety approach is risk assessment and management. By conducting
          thorough risk assessments, organizations can identify potential hazards and evaluate the level of risk
          associated with each. This includes assessing physical hazards such as machinery and equipment, as well as
          psychosocial hazards such as stress and workplace bullying. Once hazards are identified, organizations can
          implement preventive measures to eliminate or mitigate risks, ensuring the safety and well-being of employees.
        </p>
        <p>
          Moreover, a comprehensive health and safety approach involves creating and implementing robust safety policies
          and procedures. These policies should outline expectations for safe work practices, procedures for reporting
          hazards and incidents, and protocols for responding to emergencies. By establishing clear guidelines and
          expectations, organizations can ensure that employees understand their responsibilities and take proactive
          measures to prevent accidents and injuries.
        </p>
        <p>
          Furthermore, training and education play a crucial role in promoting a culture of safety within the workplace.
          Employees should receive comprehensive training on safety policies and procedures, as well as specific
          training related to their roles and responsibilities. This includes training on proper equipment use,
          emergency procedures, and hazard recognition. By investing in ongoing training and education, organizations
          can empower employees to make informed decisions and take ownership of their safety and the safety of their
          colleagues.
        </p>
        <p>
          In addition to preventive measures, organizations must also have systems in place for monitoring and
          evaluating health and safety performance. This includes conducting regular inspections and audits to identify
          potential hazards and ensure compliance with safety regulations. By monitoring performance metrics such as
          incident rates, near misses, and safety culture surveys, organizations can identify areas for improvement and
          take corrective action to address deficiencies.
        </p>
        <p>
          Moreover, a comprehensive health and safety approach extends beyond physical safety to encompass employee
          health and well-being. This includes promoting healthy lifestyle choices, providing access to resources for
          managing stress and mental health, and offering support for employees dealing with work-related injuries or
          illnesses. By prioritizing employee health and well-being, organizations can create a positive work
          environment where employees feel valued, supported, and empowered to perform their best.
        </p>
        <p>
          In conclusion, a comprehensive approach to health and safety is essential for creating a safe and sound
          workplace where employees can thrive. By identifying hazards, implementing preventive measures, and promoting
          a culture of safety, organizations can protect the health and well-being of employees while enhancing
          productivity and organizational success. In an increasingly complex and challenging work environment,
          organizations that prioritize health and safety are better positioned to attract and retain top talent, drive
          innovation, and achieve long-term success.
        </p>
      </>
    ),
  },
  {
    title: "Mastering the Workspace Symphony: Property and Building Maintenance Secrets Revealed",
    image: property,
    text: (
      <>
        <p>
          In the grand orchestration of a successful business, the physical workspace serves as the stage where the
          symphony of productivity, creativity, and collaboration unfolds. Just as a skilled conductor ensures harmony
          among the musicians, effective property and building maintenance is essential for creating an environment
          where employees can thrive. In this article, we unveil the secrets to mastering the workspace symphony through
          strategic property and building maintenance.
        </p>
        <p>
          At the heart of property and building maintenance lies a commitment to preserving and enhancing the physical
          infrastructure of the workplace. From office buildings and commercial spaces to industrial facilities and
          residential complexes, effective maintenance ensures that the environment is safe, functional, and conducive
          to productivity. This involves a proactive approach to identifying and addressing issues before they escalate,
          whether it's repairing a leaky roof, servicing HVAC systems, or maintaining landscaping and grounds.
        </p>
        <p>
          One of the secrets to mastering property and building maintenance is strategic planning and prioritization.
          Instead of taking a reactive approach to maintenance, organizations should develop comprehensive maintenance
          plans that outline priorities, schedules, and resources for ongoing upkeep. By conducting regular inspections
          and assessments, organizations can identify maintenance needs and allocate resources effectively, ensuring
          that critical systems and components are well-maintained and operational.
        </p>
        <p>
          Moreover, effective property and building maintenance require a multidisciplinary approach that encompasses a
          wide range of trades and specialties. From plumbers and electricians to HVAC technicians and landscapers, each
          member of the maintenance team plays a crucial role in keeping the workspace running smoothly. By assembling a
          skilled and knowledgeable maintenance team and fostering a culture of collaboration and accountability,
          organizations can ensure that maintenance tasks are completed efficiently and effectively.
        </p>
        <p>
          Furthermore, technology plays a pivotal role in modern property and building maintenance, enabling
          organizations to streamline processes, enhance visibility, and optimize performance. From computerized
          maintenance management systems (CMMS) to IoT sensors and predictive analytics, technology solutions provide
          real-time insights into the condition of building systems and equipment, allowing organizations to identify
          issues proactively and optimize maintenance schedules for maximum efficiency.
        </p>
        <p>
          In addition to preserving physical assets, property and building maintenance also contribute to creating a
          positive and inviting work environment. Whether it's maintaining a clean and well-kept appearance, updating
          interior finishes and furnishings, or implementing sustainable practices to reduce environmental impact,
          maintenance efforts can enhance employee morale, satisfaction, and overall well-being.
        </p>
        <p>
          In conclusion, mastering the workspace symphony through effective property and building maintenance requires a
          strategic, multidisciplinary, and technology-driven approach. By prioritizing proactive maintenance,
          assembling a skilled maintenance team, leveraging technology solutions, and creating a welcoming work
          environment, organizations can create a workspace where employees feel safe, comfortable, and inspired to
          perform their best. In an era where the workplace is constantly evolving, organizations that prioritize
          property and building maintenance are better positioned to attract and retain top talent, drive productivity,
          and achieve long-term success.
        </p>
      </>
    ),
  },
  {
    title: "Assets 360: Strategic Asset Management for Future-Ready Businesses",
    image: asset,
    text: (
      <>
        <p>
          In the ever-evolving landscape of modern business, where change is constant and competition fierce, the
          ability to effectively manage assets has never been more critical. From physical infrastructure and equipment
          to intellectual property and human capital, assets are the lifeblood of organizations, driving productivity,
          innovation, and growth. In this article, we delve into the concept of Assets 360 and explore how strategic
          asset management can position businesses for success in an uncertain future.
        </p>
        <p>
          At its core, Assets 360 represents a holistic approach to asset management that extends beyond traditional
          practices to encompass the entire lifecycle of assets. Instead of viewing assets as static entities to be
          maintained and replaced, Assets 360 recognizes the dynamic nature of assets and the need for proactive
          strategies to maximize their value and impact. This includes identifying opportunities for optimization,
          innovation, and growth throughout the asset lifecycle, from acquisition to disposal.
        </p>
        <p>
          One of the key principles of Assets 360 is strategic planning and foresight. Instead of reacting to changes
          and challenges as they arise, organizations must take a proactive approach to asset management, anticipating
          future needs and opportunities and developing strategies to capitalize on them. This involves conducting
          comprehensive asset inventories, assessing asset performance and condition, and aligning asset management
          strategies with business goals and objectives.
        </p>
        <p>
          Moreover, effective asset management requires organizations to leverage technology and data analytics to gain
          insights into asset performance and optimize asset utilization. From asset tracking and maintenance management
          systems to predictive analytics and IoT sensors, technology solutions provide organizations with real-time
          visibility into asset health, allowing them to identify issues proactively and make data-driven decisions to
          optimize asset performance and reliability.
        </p>
        <p>
          Furthermore, strategic asset management involves a focus on sustainability and resilience. In today's
          increasingly complex and interconnected world, organizations must consider the environmental, social, and
          economic impacts of their assets and operations. This includes implementing sustainable practices to reduce
          resource consumption and environmental footprint, as well as building resilience to withstand disruptions and
          uncertainties.
        </p>
        <p>
          In addition to physical assets, strategic asset management also encompasses human capital and intellectual
          property. Employees are one of the most valuable assets of any organization, and effective asset management
          involves investing in their development, engagement, and retention. This includes providing opportunities for
          training and development, fostering a culture of innovation and collaboration, and recognizing and rewarding
          employee contributions.
        </p>
        <p>
          In conclusion, Assets 360 represents a strategic and holistic approach to asset management that is essential
          for future-ready businesses. By taking a proactive approach to asset management, leveraging technology and
          data analytics, and prioritizing sustainability and resilience, organizations can unlock the full potential of
          their assets and position themselves for success in an uncertain future. In an era where change is constant
          and competition fierce, Assets 360 is the key to building a resilient, agile, and future-ready business that
          can thrive in the face of adversity.
        </p>{" "}
      </>
    ),
  },
];

export default function BlogPage() {
  const [title, setTitle] = useState("");
  const [image, setImage] = useState("");
  const [text, setText] = useState("");

  useEffect(() => {
    const fullurl = window.location.href;
    const url = fullurl.split("blog/")[1];

    if (url === "support") {
      setTitle(blogs[0].title);
      setImage(blogs[0].image);
      setText(blogs[0].text);
    }
    if (url === "facilities") {
      setTitle(blogs[1].title);
      setImage(blogs[1].image);
      setText(blogs[1].text);
    }
    if (url === "hr") {
      setTitle(blogs[2].title);
      setImage(blogs[2].image);
      setText(blogs[2].text);
    }
    if (url === "healthandsafety") {
      setTitle(blogs[3].title);
      setImage(blogs[3].image);
      setText(blogs[3].text);
    }
    if (url === "property") {
      setTitle(blogs[4].title);
      setImage(blogs[4].image);
      setText(blogs[4].text);
    }
    if (url === "assets") {
      setTitle(blogs[5].title);
      setImage(blogs[5].image);
      setText(blogs[5].text);
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth={false}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            marginTop: "5%",
            padding: "0% 5% 0% 5%",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box
              component="img"
              alt="SectorImage"
              src={image}
              sx={{
                height: "auto",
                maxWidth: "100%",
                padding: "2%",
              }}
            />
          </Box>
          <Box sx={{ margin: "2%" }}>
            <Typography variant="body1" textAlign="justify" display="block">
              {text}
            </Typography>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
