import { Card, CardContent, CardMedia } from "@mui/material";
import { Typography, Box } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { Link } from "react-router-dom";
import theme from "../../theme";

export default function BlogCard(props) {
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex" }}>
        <Link to={props.data.link} style={{ color: theme.palette.primary.main, textDecoration: "none" }}>
          <Card sx={{ width: "auto" }}>
            <CardMedia
              component="img"
              height="auto"
              sx={{ maxHeight: "300px", maxWidth: "400px" }}
              image={props.data.image}
              alt={props.data.alt}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                padding: "15px 10px 15px 10px",
              }}
            >
              <Typography variant="h5">{props.data.title}</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                padding: "2%",
              }}
            >
              <Typography variant="body2">{props.data.snippet} </Typography>
            </Box>
          </Card>
        </Link>
      </Box>
    </ThemeProvider>
  );
}
