import { createTheme } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import { createBreakpoints } from "@mui/system";

const breakpoints = createBreakpoints({ xs: 0, sm: 600, md: 960, lg: 1280, xl: 1920 });

const rawTheme = createTheme({
  palette: {
    primary: {
      light: "#87c563",
      main: "#29ba74", // red colour
      //main: "#2d2b2c",
      dark: "#197a56",
    },
    secondary: {
      light: "#f2f2f2",
      main: "#323232", // dark grey colour
      dark: "#333333",
    },
    tertiary: {
      light: "#ffffff",
      main: "#630C06", //dark red
      dark: "#ffffff",
    },
    contrast: {
      light: "#ffffff",
      main: "#f4f5fa", //tertiary light grey colour
      dark: "#ffffff",
    },
    warning: {
      main: "#ffffff",
      dark: "#ffffff",
    },
    error: {
      xLight: "#FFFFFF",
      main: "#BA1313",
      dark: "#FFFFFF",
    },
    success: {
      xLight: "#FFFFFF",
      main: "#09BA3C",
      dark: "#FFFFFF",
    },
    // font colours with different %
    typographyColor: {
      black: "#001C18",
      black75: "#3E5451",
      black50: "#7F8D8B",
      black25: "##BEC6C5",
      black10: "#E5E8E",
      black5: "#F2F4F3",
      white: "#FAF9F6",
    },
  },
  typography: {
    fontFamily: "Work Sans",
    fontWeight300: 300,
    fontWeight400: 400,
    fontWeight500: 500,
    fontWeight600: 600,
    fontWeight700: 700,
    fontWeight800: 800,
    fontWeight900: 900,
    fontFamilySecondary: "Open Sans",
  },
  components: {
    // Name of the component
    MuiContainer: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          display: "flex",
          justifyContent: "center",

          [breakpoints.down("sm")]: {
            padding: "0px 0px 0px 0px",
          },
          [breakpoints.down("md")]: {
            padding: "0px 0px 0px 0px",
          },
          [breakpoints.up("md")]: {
            padding: "0px 0px 0px 0px",
          },
          [breakpoints.up("lg")]: {
            padding: "0px 0px 0px 0px",
          },
        },
      },
    },
    MuiBox: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          padding: "0px 0px 0px 0px",
          display: "flex",
          justifyContent: "center",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {},
      },
    },
    MuiCard: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          maxWidth: 345,
          //Less than 600px
          [breakpoints.down("sm")]: {
            maxWidth: 250,
            maxHeight: 700,
            height: 380,
          },

          //More than 600px until 960px
          [breakpoints.down("md")]: {
            maxWidth: 250,
            maxHeight: 700,
            height: 380,
          },

          //More than 960px
          [breakpoints.up("md")]: {
            maxWidth: 300,
            maxHeight: 700,
            height: 430,
          },

          //More than 1280px
          [breakpoints.up("lg")]: {
            maxWidth: 300,
            height: 470,
            maxHeight: 600,
          },
          //More than 1920px
          [breakpoints.up("xl")]: {
            maxWidth: 300,
            height: 470,
            maxHeight: 600,
          },
        },
      },
    },
    MuiImageListItem: {
      styleOverrides: {
        root: {
          "&:hover": {
            opacity: 0.5,
            boxShadow: 2,
          },
          [breakpoints.down("sm")]: {
            cols: 1,
          },

          //More than 600px until 960px
          [breakpoints.down("md")]: {
            cols: 2,
          },

          //More than 960px
          [breakpoints.up("md")]: {
            cols: 4,
          },

          //More than 1280px
          [breakpoints.up("lg")]: {
            cols: 4,
          },
          //More than 1920px
          [breakpoints.up("xl")]: {
            cols: 6,
          },
        },
      },
    },
  },
});

const fontHeader = {
  color: rawTheme.palette.text.primary,
  fontWeight: rawTheme.typography.fontWeight500,
  fontFamily: rawTheme.typography.fontFamilySecondary,
  textTransform: "uppercase",
};

const theme = {
  ...rawTheme,
  palette: {
    ...rawTheme.palette,
    background: {
      ...rawTheme.palette.background,
      default: rawTheme.palette.common.white,
      placeholder: grey[200],
    },
  },
  typography: {
    ...rawTheme.typography,
    fontHeader,
    //hero section red text
    h1: {
      ...rawTheme.typography.h1,
      fontFamily: rawTheme.typography.fontFamily,
      fontWeight: rawTheme.typography.fontWeight600,
      color: rawTheme.palette.typographyColor.white,
      //Less than 600px
      [breakpoints.down("sm")]: {
        fontSize: "2.5em",
        letterSpacing: "-0.125rem",
        lineHeight: "3rem",
      },
      //More than 600px until 960px
      [breakpoints.up("sm")]: {
        fontSize: "3em",
        letterSpacing: "-0.125rem",
        lineHeight: "4.5rem",
      },
      //More than 960px
      [breakpoints.up("md")]: {
        fontSize: "3em",
        letterSpacing: "-0.125rem",
        lineHeight: "5rem",
      },
      //More than 1280px
      [breakpoints.up("lg")]: {
        fontSize: "4em",
        letterSpacing: "-0.125rem",
        lineHeight: "5rem",
      },
      //More than 1280px
      [breakpoints.up("xl")]: {
        fontSize: "4em",
        letterSpacing: "-0.125rem",
        lineHeight: "5rem",
      },
    },
    h2: {
      ...rawTheme.typography.h2,
      fontFamily: rawTheme.typography.fontFamily,
      letterSpacing: "-0.125rem",
      lineHeight: "3rem",
      fontWeight: rawTheme.typography.fontWeight500,
      color: rawTheme.palette.typographyColor.white,

      //Less than 600px
      [breakpoints.down("sm")]: {
        fontSize: "1.5em",
        letterSpacing: "-0.125rem",
        lineHeight: "2rem",
      },
      //More than 600px until 960px
      [breakpoints.up("sm")]: {
        fontSize: "1.5em",
        letterSpacing: "-0.125rem",
        lineHeight: "2rem",
      },
      //More than 960px
      [breakpoints.up("md")]: {
        fontSize: "1.8em",
        letterSpacing: "-0.125rem",
        lineHeight: "2.4rem",
      },
      //More than 1280px
      [breakpoints.up("lg")]: {
        fontSize: "1.8em",
        letterSpacing: "-0.125rem",
        lineHeight: "2.4rem",
      },
      //More than 1280px
      [breakpoints.up("xl")]: {
        fontSize: "2em",
        letterSpacing: "-0.125rem",
        lineHeight: "3rem",
      },
    },
    h3: {
      ...rawTheme.typography.h3,
      fontFamily: rawTheme.typography.fontFamilySecondary,
      fontWeight: rawTheme.typography.fontWeight600,
      color: rawTheme.palette.secondary.main,
      //Less than 600px
      [breakpoints.down("sm")]: {
        fontSize: "2em",
        letterSpacing: "-0.125rem",
        lineHeight: "4rem",
      },
      //More than 600px until 960px
      [breakpoints.up("sm")]: {
        fontSize: "2em",
        letterSpacing: "-0.125rem",
        lineHeight: "4.5rem",
      },
      //More than 960px
      [breakpoints.up("md")]: {
        fontSize: "2.5em",
        letterSpacing: "-0.125rem",
        lineHeight: "5rem",
      },
      //More than 1280px
      [breakpoints.up("lg")]: {
        fontSize: "2.5em",
        letterSpacing: "-0.125rem",
        lineHeight: "5rem",
      },
      //More than 1280px
      [breakpoints.up("xl")]: {
        fontSize: "3em",
        letterSpacing: "-0.125rem",
        lineHeight: "5.5rem",
      },
    },
    h4: {
      //blog titles
      ...rawTheme.typography.h3,
      fontFamily: rawTheme.typography.fontFamily,
      fontWeight: rawTheme.typography.fontWeight600,
      color: rawTheme.palette.primary.main,
      //Less than 600px
      [breakpoints.down("sm")]: {
        fontSize: "1.5em",
        letterSpacing: "-0.125rem",
        lineHeight: "2rem",
      },
      //More than 600px until 960px
      [breakpoints.up("sm")]: {
        fontSize: "1.5em",
        letterSpacing: "-0.125rem",
        lineHeight: "2rem",
      },
      //More than 960px
      [breakpoints.up("md")]: {
        fontSize: "1.8em",
        letterSpacing: "-0.125rem",
        lineHeight: "2.4rem",
      },
      //More than 1280px
      [breakpoints.up("lg")]: {
        fontSize: "1.8em",
        letterSpacing: "-0.125rem",
        lineHeight: "2.4rem",
      },
      //More than 1280px
      [breakpoints.up("xl")]: {
        fontSize: "2em",
        letterSpacing: "-0.125rem",
        lineHeight: "3rem",
      },
    },
    h5: {
      ...rawTheme.typography.h5,
      fontFamily: rawTheme.typography.fontFamilySecondary,
      letterSpacing: "-0.0375rem",
      lineHeight: "1.083rem",
      fontWeight: rawTheme.typography.fontWeightRegular,
      color: rawTheme.palette.typographyColor.black,

      [breakpoints.down("sm")]: {
        fontSize: "1rem",
        lineHeight: "1rem",
      },
      [breakpoints.down("md")]: {
        fontSize: "1rem",
        lineHeight: "1.5rem",
      },
      [breakpoints.up("md")]: {
        fontSize: "1.125rem",
        lineHeight: "2rem",
      },
      [breakpoints.up("lg")]: {
        fontSize: "1.125rem",
        lineHeight: "2rem",
      },
    },
    h6: {
      ...rawTheme.typography.h6,
      fontFamily: rawTheme.typography.fontFamily,
      fontWeight: rawTheme.typography.fontWeightRegular,

      [breakpoints.down("sm")]: {
        fontSize: "1rem",
        lineHeight: "1.8rem",
      },
      [breakpoints.down("md")]: {
        fontSize: "1.125rem",
        lineHeight: "1.8rem",
      },
      [breakpoints.up("md")]: {
        fontSize: "1.2rem",
        lineHeight: "2rem",
      },
      [breakpoints.up("lg")]: {
        fontSize: "1.8rem",
        lineHeight: "2rem",
      },
    },
    subtitle1: {
      ...rawTheme.typography.subtitle1,
      fontSize: 18,
    },
    body1: {
      ...rawTheme.typography.fontFamily,
      fontWeight: rawTheme.typography.fontWeightRegular,
      [breakpoints.down("sm")]: {
        fontSize: "1.125rem",
        lineHeight: "1rem",
      },
      [breakpoints.down("md")]: {
        fontSize: "1.125rem",
        lineHeight: "1.5rem",
      },
      [breakpoints.up("md")]: {
        fontSize: "1.125rem",
        lineHeight: "2rem",
      },
      [breakpoints.up("lg")]: {
        fontSize: "1.25rem",
        lineHeight: "2rem",
      },
    },
    body2: {
      ...rawTheme.typography.body2,
      fontSize: 14,
    },
    button: {
      ...rawTheme.button,
      fontFamily: rawTheme.typography.fontFamily,
      letterSpacing: "-1",
      lineHeight: "3rem",
      fontWeight: rawTheme.typography.fontWeightMedium,
      color: rawTheme.palette.typographyColor.white,
      //Less than 600px
      [breakpoints.down("sm")]: {
        fontSize: "1.2em",
        letterSpacing: "-0.125rem",
        lineHeight: "2rem",
      },
      //More than 600px until 960px
      [breakpoints.up("sm")]: {
        fontSize: "1.5em",
        letterSpacing: "-0.125rem",
        lineHeight: "2rem",
      },
      //More than 960px
      [breakpoints.up("md")]: {
        fontSize: "1.5em",
        letterSpacing: "-0.125rem",
        lineHeight: "2rem",
      },
      //More than 1280px
      [breakpoints.up("lg")]: {
        fontSize: "1.5em",
        letterSpacing: "-0.125rem",
        lineHeight: "2.3rem",
      },
      //More than 1280px
      [breakpoints.up("xl")]: {
        fontSize: "1.5em",
        letterSpacing: "-0.125rem",
        lineHeight: "2.5rem",
      },
    },
    caption: {
      ...rawTheme.typography.caption,
      fontSize: 14,
    },
    overline: {
      ...rawTheme.typography.overline,
      ...fontHeader,
      fontSize: 18,
    },
  },
};

export default theme;
