import React from "react";
import { Container, Typography, Paper } from "@mui/material";
import { CardMedia, Divider } from "@mui/material";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";

import theme from "../../theme";
import logistics from "../../../img/categories/logistics.jpg";
import hr from "../../../img/categories/hr.jpg";
import assets from "../../../img/categories/asset.jpg";
import maintenance from "../../../img/categories/maintenance.jpg";
import healthandsafety from "../../../img/categories/healthandsafety.jpg";
import projectmanagement from "../../../img/categories/projectmanagement.jpg";

import CompanyName from "../../utils/CompanyName";

export default function Sectors(props) {
  const images = [
    {
      url: logistics,
      title: "Logistics",
      link: "/sector/logistics",
    },
    {
      url: hr,
      title: "Human Resources",
      link: "/sector/hr",
    },
    {
      url: healthandsafety,
      title: "Health and Safety and Risk Management",
      link: "/sector/healthandsafety",
    },
    {
      url: projectmanagement,
      title: "Project Management and Business Continuity",
      link: "/sector/projectmanagement",
    },
    {
      url: maintenance,
      title: "Property and Building Maintenance",
      link: "/sector/maintenance",
    },
    {
      url: assets,
      title: "Asset Management",
      link: "/sector/assets",
    },
  ];

  return (
    <Container maxWidth="xl" sx={{ marginTop: theme.spacing(4) }}>
      <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
        <Box sx={{ justifyContent: "left", marginBottom: "2%" }}>
          <Divider textAlign="left">
            <Typography align="left" variant="h3">
              Our solutions
            </Typography>
          </Divider>
          <Box
            sx={{
              padding: "0% 5% 0% 5%",
              marginTop: "2%",
            }}
          >
            <Typography variant="h5" align="left" margin="auto">
              <CompanyName />
              's provides short, medium and long-term business support and facilities management solutions to
              organisations.
            </Typography>
          </Box>
        </Box>
        <Box sx={{ width: "100%", justifyContent: "center", display: "flex", flexDirection: "column" }}>
          {images.map((image) => (
            <Link to={image.link} style={{ textDecoration: "none" }}>
              <Paper elevation={false} sx={{ position: "relative" }}>
                <CardMedia
                  component="img"
                  image={image.url}
                  alt={image.title}
                  sx={{
                    maxHeight: "300px",
                    maxWidth: "100%",
                    height: "100%",
                    width: "100%",
                    paddingBottom: "2%",
                    filter: "brightness(50%)",
                    "&:hover": {
                      opacity: 0.8,
                    },
                  }}
                />
                <Box sx={{ position: "absolute", top: "40%", left: "20%", color: "white", display: "flex" }}>
                  <DoubleArrowIcon sx={{ color: theme.palette.primary.main, alignSelf: "center" }} />
                  <Typography variant="h4" color="white">
                    {image.title}
                  </Typography>
                </Box>
              </Paper>
            </Link>
          ))}
        </Box>
      </Box>
    </Container>
  );
}
