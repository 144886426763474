import React from "react";
import { ThemeProvider, Typography, Container, Box, Grid, Divider } from "@mui/material";
import { withStyles } from "@mui/styles";
import theme from "../../theme";

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(4),
  },
  container: {
    marginTop: theme.spacing(15),
    marginBottom: theme.spacing(30),
    display: "flex",
    position: "relative",
  },

  item: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(0, 5),
  },
  image: {
    height: 55,
  },
  sideimg: {
    width: "80%",
    height: "80%",
    paddingLeft: 70,
    paddingTop: 40,
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  h6: {
    marginTop: theme.spacing(2),
  },
  para: {
    fontSize: "20px",
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
    textAlign: "justify",
    display: "block",
  },
});

function Contact(props) {
  return (
    <ThemeProvider>
      <Container maxWidth="xl" sx={{ marginTop: theme.spacing(4) }}>
        <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
          <Box sx={{ justifyContent: "left", marginBottom: "2%" }}>
            <Divider textAlign="left">
              <Typography align="left" variant="h3">
                Contact Us{" "}
              </Typography>
            </Divider>
            <Box
              sx={{
                display: "flex",
                marginBottom: "5%",
                padding: "0% 5% 0% 5%",
              }}
            >
              <Grid container spacing={5} direction="column" justifyContent="center" alignItems="center">
                <Grid item xs="auto">
                  <Typography sx={{ color: theme.palette.primary.main, marginTop: theme.spacing(8) }} variant="h3">
                    We would love to hear from you.
                  </Typography>
                </Grid>
                <Grid item xs="auto">
                  <Typography sx={{ marginTop: theme.spacing(2) }} variant="body1">
                    Contact us at: contact@jkc-solutions.com
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default withStyles(styles)(Contact);
